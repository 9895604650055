import logo from './logo.svg';
import './App.css';
import Whoop from "./Whoop"
import VTO from './vto/vto';
import Header from "./Header"
import { Icon, Card, List, Layout } from 'antd';
import { HashRouter as Router, Route, Switch, Link } from "react-router-dom";
import { isBrowser, isMobile } from "react-device-detect";
import "antd/dist/antd.css";
import {
  Asset,
  DisplayAttribute,
  Spinner,
  ThreekitSource,
  ThreekitCanvas
} from '@threekit/lignin';
import { Bounds, Environment, OrbitControls, } from '@react-three/drei';
import ReactGA from 'react-ga';




const ProductList = {
  1: {
    imageURL:
      "https://preview.threekit.com/api/files/f81a1fa4-44e5-40ef-9327-4d1cd12232bf/content",
    name: "Glasses",
  },
  2: {
    imageURL:
      "https://preview.threekit.com/api/files/e844be18-02a8-47d2-83c4-85f0673898e3/content",
    name: "Shoe",
  },
  3: {
    imageURL: "https://preview.threekit.com/api/files/f4ad51b1-6391-40c4-bda8-0166f362000f/content",
    name: "Jacket",
  },
};
const productIds = [];

for (var i in ProductList) {
  productIds.push(i);
}

function PLP() {
  ReactGA.initialize("UA-63905846-9");
  ReactGA.set({ dimension1: "Clothing Demo" });
  ReactGA.pageview("Clothing Demo");
  return (
    <List grid={{ xs: 1, sm: 2, md: 3, lg: 4, xl: 5, xxl: 5 }} dataSource={productIds} renderItem={item => (
      <Link to={ProductList[item].name}>
        <List.Item>
          <Card
            // eslint-disable-next-line jsx-a11y/alt-text
            cover={<img style={{ maxHeight: 200, width: (isMobile ? "auto" : "auto"), marginLeft: "auto", marginRight: "auto" }} src={ProductList[item].imageURL}></img>}
          >
            <Card.Meta
              title={ProductList[item].name}
            />
          </Card>
        </List.Item>
      </Link>)
    }>
    </List>
  )
}


function App() {
  try {

    return (
      <div className="App">
        <Header></Header>
        <Router>
          <Switch>
            <Route
              path="/Glasses"
              exact
              component={() => <Whoop
                pages={4}
                assetId={'cc5b4609-f088-430b-b7ec-473ef538414f'}
                links={{
                  snapchat: "https://www.snapchat.com/unlock/?type=SNAPCODE&uuid=29c143d8deb54b77a735de927135845b&metadata=01",
                  instagram: "https://www.instagram.com/ar/731709118139978/?ch=N2Y2OGM5MTg3ODdiMjdlOWMwNDJhYmEwYmYzMzJiMjk%3D",
                  vto: "https://glasses-vto.demo.threekit.com/"
                }}
                translations={[
                  { x: -2, y: 0, z: -0.5 },
                  { x: 2, y: 0, z: -0.5 },
                  { x: -2, y: 0, z: -0.5 },
                  { x: 2, y: 0, z: -0.5 }
                ]}
                rotations={[
                  {
                    x: 0,
                    y: 0,
                    z: 0
                  },
                  {
                    x: 0,
                    y: 1.5,
                    z: 0
                  },
                  {
                    x: 0,
                    y: 6.5,
                    z: 0
                  },
                  {
                    x: 0,
                    y: 0,
                    z: 0
                  },
                ]
                }
                parentScale={[5, 5, 5]}
              ></Whoop>}
            ></Route>
            <Route
              path="/Shoe"
              exact
              component={() => <Whoop
                pages={1}
                assetId={'ec06b347-584b-478d-b121-3ed2ec780d3d'}
                links={{
                  snapchat: "https://www.snapchat.com/unlock/?type=SNAPCODE&uuid=6fb8d3959c7e4fa79396e12fae5e44a6&metadata=01",
                }}
                translations={[{ x: -3, y: 0, z: -0.5 }]}
                rotations={[{
                  x: 0,
                  y: -1,
                  z: 0
                }
                ]
                }
                parentScale={[10, 10, 10]}
              ></Whoop>}
            ></Route>
            <Route
              path="/Jacket"
              exact
              component={() => <Whoop
                pages={3}
                assetId={'b137e83b-689d-4c10-8275-360c100ca541'}
                links={{
                }}
                translations={[
                  { x: -3, y: -5, z: -0.5 },
                  { x: 3, y: -5, z: -0.5 },
                  { x: -3, y: -5, z: -0.5 },
                  { x: 0, y: -5, z: -0.5 }
                ]}
                rotations={[
                  {
                    x: 0,
                    y: 0,
                    z: 0
                  },
                  {
                    x: 0,
                    y: 1.5,
                    z: 0
                  },
                  {
                    x: 0,
                    y: 6.5,
                    z: 0
                  },
                  {
                    x: 0,
                    y: 0,
                    z: 0
                  },
                ]
                }
                parentScale={[5, 5, 5]}
              ></Whoop>
              }
            ></Route>
            <Route path="/" component={PLP}></Route>
            <Route component={PLP}></Route>
          </Switch>
        </Router>
      </div>
    );

  } catch (error) {
    console.log(error)
    window.location.reload()
  }
}

export default App;
