import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import {
  Asset,
  DisplayAttribute,
  Spinner,
  ThreekitSource,
  useConfigurator,
  useAsset
} from '@threekit/lignin';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { flexbox } from '@mui/system';
import Typography from '@mui/material/Typography';


export default function Configurator(props) {
  const [nodeExists, setNodeExists] = useState(false);
  useEffect(() => {
    var observer = new MutationObserver(function (mutations) {
      if (document.getElementById('portal-1')) {
        setNodeExists(true)
        observer.disconnect();
      }
    });
    if (!nodeExists) {
      observer.observe(document, { attributes: false, childList: true, characterData: false, subtree: true })
    }
  }, []);



  const [assetState, setState] = useAsset({
    refkey: 'my-model',
    id: props.assetId,
  });
  const [attributes, setConf] = useConfigurator('my-model');

  const { attrs, onChange } = props;
  useEffect(() => {
  }, [props.attrs]);

  function changeOption(name) {
    return (ev) => {
      onChange({ [name]: ev.target.value });
    };
  }
  function changeOptionPartRef(name) {
    return (ev) => {
      console.log('change', name, 'to', ev.target.value);
      console.log(ev.target.value)
      onChange({ [name]: {assetId:ev.target.value} });
    };
  }
  return (
    <ul style={{ position: 'absolute', top: 0, left: 0 }}>
      {attributes.map((attr,i) => {
        console.log(attr)
        if(attr.name != "Mannequin") {

        if (attr.name === 'Cameras') return;
        if (!attr.visible) return null;
        if (attr.type === 'String') {
          return (
            nodeExists && document.getElementById("portal-1") ? ReactDOM.createPortal(
              <div>
                <h6>
                  {attr.name}
                </h6>
                <Select
                  name={attr.name}
                  onChange={changeOption(attr.name)}
                  value={attr.value}
                  disabled={!attr.enabled}
                >
                  {attr.values.map((v,i) => {
                    return (
                      <MenuItem key={i} value={v.value}>
                        {v.label}
                      </MenuItem>
                    );
                  })}
                </Select>
              </div>, document.getElementById(`portal-${i + 1}`)
            ) : null
          )
        }
        if (attr.type === 'Asset') {
          return (
            nodeExists && document.getElementById("portal-1") ? ReactDOM.createPortal(
            <div>
                <h6>
                  {attr.name}
                </h6>
                <div style={{
                  display:"flex"
                }}>
                {attr.values.map((v) => {
                  return (
                    <button style={{
                      pointerEvents:"all",
                      backgroundColor:v.metadata["_thumbnail"],
                      zIndex:9999
                    }} class="swatch-button" onClick={changeOptionPartRef(attr.name)} key={v.assetId} value={v.assetId}>
                      {/* {v.name} */}
                    </button>
                  );
                })}
              {/* </Select> */}
                </div>
            </div>, document.getElementById(`portal-${i+1}`)
          ): null
          )
        }
        return (
          <li key={attr.id}>
            {attr.name} - {attr.type}, {JSON.stringify(attr.value)},{' '}
            {JSON.stringify(attr.values)}
          </li>
        );
      }})}
    </ul>
  );
}
